<app-header title="umanweb"></app-header>
<h1>{{ "buySubscriptionTitle" | translate | capitalizeFirstLetter }}</h1>

<div class="container">
  <div style="
  display: flex;
  flex-direction: column;
  align-items: center;
  ">
    <h2 style="font-size: xx-large;">{{ (plan=="monthly") ? "85CHF / " + ("month" | translate): "850CHF / "+("year" | translate) }}</h2>
    <a mat-raised-button color="primary" routerLink="/regularizePayment" routerDirection="root" style="
        width: 240px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20%;
      ">
      <mat-icon color="accent">arrow_left</mat-icon> {{ "selectOtherPlan" | translate | capitalizeFirstLetter }}
    </a>
  </div>
  <div style="
  border-width: 1px;
  border-style: dashed;
  padding: 2%;
  ">

    <ngx-stripe-card class="stripe" [options]="cardOptions" (change)="cardChange($event)" [elementsOptions]="elementsOptions"></ngx-stripe-card>
    <br>
    <button mat-flat-button color="primary" (click)="createToken()" [disabled]="isLoading || !isCardValid" type="submit" style="
      min-height: 36px;
      border-radius: 0px;
      text-align: -webkit-center;
      display: block;
      background-color: #FD3F92;
      color: white;
      margin: auto;
      margin-right: auto;
    ">
      <span *ngIf="!isLoading"> {{ "buy" | translate | capitalizeFirstLetter }}! </span>
      <mat-progress-spinner *ngIf="isLoading" [diameter]="24" [mode]="'indeterminate'" style="display: block;" color="accent">
      </mat-progress-spinner>
    </button>
    <div class="error" *ngIf="errorMessage">
      <h2>{{ "billingErrorTitle" | translate | capitalizeFirstLetter }}</h2>
      <h3>{{errorMessage}}</h3>
    </div>
  </div>

</div>