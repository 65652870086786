import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuySubscriptionComponent } from './buy-subscription/buy-subscription.component';
import { RegularizePaymentComponent } from './regularize-payment.component';
import { RegularizePaymentGuard } from './regularize-payment.guard';
import { SubscriptionSuccessComponent } from './subscription-success/subscription-success.component';

const routes: Routes = [
  { 
    path: '', 
    component: RegularizePaymentComponent 
  },
  { 
    path: 'buySubscription', 
    component: BuySubscriptionComponent 
  },
  { 
    path: 'subscriptionSuccess', 
    component: SubscriptionSuccessComponent,
    canActivateChild: [RegularizePaymentGuard],
  },
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegularizePaymentRoutingModule {}
