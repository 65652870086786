<div class="content">
  <app-header></app-header>
  <ion-row>
    <ion-col style="
          text-align: center;
          height: 80vh;
          place-items: center;
          display: grid;
        " size="12">
      <h2>{{ "choosePlan" | translate | capitalizeFirstLetter }}</h2>
      <div style="width: 100%; display:flex; justify-content: center;">
        <div class="columns">
          <ul class="price">
            <li class="header">{{ "monthly" | translate | capitalizeFirstLetter }}</li>
            <li class="subheader"> 85CHF / {{ "month" | translate }}</li>
            <li>{{ "monthlyPaymentDescription" | translate | capitalizeFirstLetter }}</li>
            <li ><a routerLink="buySubscription" [queryParams]="{plan: 'monthly'}" class="button">{{ "choose" | translate | capitalizeFirstLetter }}!</a></li>
          </ul>
        </div>
        <div class="columns">
          <ul class="price">
            <li class="header">{{ "yearly" | translate | capitalizeFirstLetter }}</li>
            <li class="subheader"> 850CHF / {{ "year" | translate }}</li>
            <li>{{ "yearlyPaymentDescription" | translate | capitalizeFirstLetter }}</li>
            <li ><a routerLink="buySubscription" [queryParams]="{plan: 'yearly'}" class="button">{{ "choose" | translate | capitalizeFirstLetter }}!</a></li>
          </ul>
        </div>
      </div>
      <h3>{{ "manualPayment" | translate | capitalizeFirstLetter }}<a
          href="javascript:location='mailto:\u006e\u0061\u0064\u0065\u0067\u0065\u0070\u0072\u0069\u0063\u0061\u0074\u0040\u0067\u006d\u0061\u0069\u006c\u002e\u0063\u006f\u006d';void 0">{{
          '\u006e\u0061\u0064\u0065\u0067\u0065\u0070\u0072\u0069\u0063\u0061\u0074\u0040\u0067\u006d\u0061\u0069\u006c\u002e\u0063\u006f\u006d'
          }}</a>
      </h3>
    </ion-col>
  </ion-row>
  <app-footer></app-footer>
</div>