import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegularizePaymentComponent } from './regularize-payment.component';
import { RegularizePaymentRoutingModule } from './regularize-payment-routing.module';
import { SharedModule } from '../shared/shared.module';
import { BuySubscriptionComponent } from './buy-subscription/buy-subscription.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { SubscriptionSuccessComponent } from './subscription-success/subscription-success.component';

@NgModule({
  declarations: [RegularizePaymentComponent, BuySubscriptionComponent, SubscriptionSuccessComponent],
  imports: [
    CommonModule, 
    RegularizePaymentRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxStripeModule.forRoot(environment.stripePK),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RegularizePaymentModule {}
