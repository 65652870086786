<app-header title="umanweb"></app-header>
<div class="container">
      <img [src]="assetsPath + '/images/logo-big.jpeg'" style="width: 240px; height: 240px" />
    <div>
        <h1>{{ "buySubscriptionSuccessTitle" | translate | capitalizeFirstLetter }}</h1>
        <h2>{{ "buySubscriptionSuccessSubTitle" | translate | capitalizeFirstLetter }}</h2>
    </div>
    <button mat-flat-button color="primary" routerLink="/start">
        <span>{{ "goHome" | translate | capitalizeFirstLetter }}</span><mat-icon>arrow_right</mat-icon>
    </button>
</div>